<template>
  <v-sheet class="booking-sidebar__container" color="white" ref="container" :style="styles">
    <div v-if="!loaded" class="loading">
      <v-progress-circular indeterminate size="48" color="accent"/>
    </div>
    <div v-else-if="deleting" class="loading">
      <div class="loading-delete">
        <v-progress-circular indeterminate size="48" color="accent"/>
        <div>deleting... {{ event && event.name }}</div>
      </div>
    </div>
    <div style="max-height: 100%;overflow: auto" v-else-if="event">
      <v-toolbar flat class="px-2" style="position:sticky;top: 0;z-index: 1">
        <v-toolbar-title class="flex-fill">
          <v-text-field
              autocomplete="off"
              class="title mr-2"
              placeholder="(no title)"
              v-model="event.name"
              hide-details
              style="min-width: 10em; max-width: initial"/>
        </v-toolbar-title>
        <v-menu offset-y left min-width="200">
          <template #activator="{on, attrs}">
            <v-btn icon v-on="on" v-bind="attrs" class="toolbar-icon">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item @click="copyLink">
                <v-list-item-icon>
                  <v-progress-circular color="accent" v-if="copyingToClipboard"/>
                  <v-icon v-else>mdi-link</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Copy Link</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="deleteBooking">
                <v-list-item-icon>
                  <v-icon>mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar>

      <dl class="info-table pa-6 pt-2">
        <dt class="field-name">Time</dt>
        <dd>
          <v-expand-transition>
            <span v-if="!showDayEditor" @click="showDayEditor = true">
              <event-time :start="event.start" :end="event.end"/>
            </span>
          </v-expand-transition>
          <v-expand-transition>
            <period-input :start.sync="event.start" :end.sync="event.end" v-if="showDayEditor"/>
          </v-expand-transition>
        </dd>

        <dt class="field-name">Owner</dt>
        <dd>
          <owner-field-readonly
              v-if="someEventsExisting && event.owner"
              :value="event.owner"/>
          <owner-field
              v-else
              class="mt-0 pt-0"
              v-model="event.owner"/>
        </dd>

        <dt class="field-name">Locations</dt>
        <dd class="full-width">
          <template v-for="k in reservationKeys">
            <reservation :key="k" :path="event.ref" :id="k"/>
          </template>
          <add-reservation :event="event" :resource-refs="bookingResourceRefs" class="add-reservation"/>
        </dd>
      </dl>

      <div class="full-width d-flex white" style="position: sticky;bottom: 0;">
        <v-spacer/>
        <div class="mb-3 mr-3">
          <v-btn text @click="reset">{{ resetText }}</v-btn>
          <v-fade-transition>
            <v-btn
                depressed
                v-if="showCommitBtn"
                @click="commit"
                :loading="committing"
                color="accent">
              {{ commitText }}
            </v-btn>
          </v-fade-transition>
        </div>
      </div>
    </div>
    <div v-else>
      <v-btn fab icon @click="close" class="toolbar-icon">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      Booking not found
    </div>
  </v-sheet>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import EventTime from '@/views/room-booking/calendar/EventTime';
import Reservation from '@/views/room-booking/calendar/booking-view/Reservation';
import OwnerFieldReadonly from '@/views/room-booking/calendar/OwnerFieldReadonly';
import OwnerField from '@/views/room-booking/calendar/OwnerField';
import PeriodInput from '@/views/room-booking/calendar/PeriodInput';
import AddReservation from '@/views/room-booking/calendar/booking-view/AddReservation';
import {byRef} from '@/store/firestore-util';

export default {
  name: 'BookingSidebar',
  components: {AddReservation, PeriodInput, OwnerField, OwnerFieldReadonly, Reservation, EventTime},
  data() {
    return {
      cancelQuery: null,
      parentEl: null,
      showDayEditor: false,
      committing: false,
      deleting: false,
      copyingToClipboard: false
    };
  },
  computed: {
    ...mapGetters('ns', ['nsRef']),
    ...mapGetters('views/roomBooking/booking', {
      eventsForBookingId: 'eventsForBookingId',
      eventsByConnectionRef: 'eventsByConnectionRef',
      bookingLoaded: 'loaded'
    }),
    ...mapGetters('views/roomBooking/booking/workOrders', {
      hasEdits: 'hasEdits',
      workOrdersLoaded: 'loaded'
    }),
    bookingId() {
      return this.$route.params.id;
    },
    bookingResourceRefs() {
      return this.events.map(e => byRef(e.resourceSnippet));
    },
    events() {
      if (!this.bookingId) {
        return [];
      }
      return this.eventsForBookingId(this.bookingId);
    },
    event() {
      return this.events[0];
    },
    reservationKeys() {
      return this.events.map(e => e.reservationKey).sort();
    },

    connectedEvents() {
      if (!this.event) {
        return [];
      }
      return this.eventsByConnectionRef(this.event.ref);
    },

    eventsHaveChanges() {
      return this.events.some(e => e.added || e.editedGraph) ||
          this.connectedEvents.some(con => con.event.added || con.event.deleted) || this.hasEdits;
    },
    showCommitBtn() {
      return this.eventsHaveChanges;
    },
    someEventsExisting() {
      return this.events.some(e => !e.added);
    },
    someEventsEdited() {
      return this.events.some(e => e.editedGraph);
    },
    commitText() {
      if (!this.someEventsExisting) return 'Create';
      return 'Save';
    },
    resetText() {
      return this.eventsHaveChanges ? 'Discard' : 'Close';
    },

    /**
     * This is the width of the scrollbar of the booking-calendar element, which we don't want to overlap,
     * but we will without an offset because we're above it (z-index > 0)
     *
     * @return {number}
     */
    parentScrollbarWidth() {
      if (!this.parentEl) {
        return 0;
      }
      const {clientWidth, offsetWidth} = this.parentEl;
      return offsetWidth - clientWidth;
    },
    styles() {
      return {
        '--parent-scollbar-width': this.parentScrollbarWidth + 'px'
      };
    },
    loaded() {
      return this.workOrdersLoaded && this.bookingLoaded;
    }
  },
  watch: {
    bookingId: {
      immediate: true,
      handler(id) {
        this.setBookingId(id);
      }
    }
  },
  beforeDestroy() {
    this.setBookingId(null);
    this.clearWordOrderStore();
  },
  mounted() {
    this.setBookingId(this.bookingId);
    if (this.$refs.container) {
      this.parentEl = this.$refs.container.$el.parentElement;
    } else {
      this.$nextTick(() => {
        if (this.$refs.container) {
          this.parentEl = this.$refs.container.$el.parentElement;
        }
      });
    }
  },
  methods: {
    ...mapActions('views/roomBooking/booking/workOrders', {
      commitWorkOrders: 'commit',
      clearWordOrderStore: 'clear'
    }),
    ...mapMutations('views/roomBooking/booking', ['setBookingId']),
    close() {
      this.$router.push({
        name: 'room-booking-calendar',
        query: this.$route.query
      });
    },

    async copyLink() {
      try {
        this.copyingToClipboard = true;
        const link = location.href;
        await navigator.clipboard.writeText(link);
        this.$notify.showSuccess(`Booking link copied to clipboard`);
      } catch (e) {
        this.$logger.error(`copyLink`, e);
      } finally {
        this.copyingToClipboard = false;
      }
    },

    async commit() {
      try {
        this.committing = true;
        // we only need to commit one event, because it saves all edits to a booking
        await this.event.commit();
        await this.commitWorkOrders();
        this.close();
      } catch (e) {
        this.$logger.error(`commit`, e);
      } finally {
        this.committing = false;
      }
    },
    reset() {
      for (const e of this.events) {
        e.reset();
      }
      this.close();
    },
    async deleteBooking() {
      try {
        this.deleting = true;
        this.event.delete();
        // we only need to commit one event, because it saves all edits to a booking
        await this.event.commit();
        this.close();
      } catch (e) {
        this.$logger.error(`commit`, e);
      } finally {
        this.deleting = false;
      }
    }
  }
};
</script>

<style scoped>
.booking-sidebar__container {
  --toolbar-height: 56px;
  --top-border: 1px;
  --parent-scollbar-width: 0;
  height: calc(100% - var(--toolbar-height) - var(--top-border));
  min-width: 440px;
  width: 35vw;
  /* same z-index as the toolbar, so we sit above its shadow */
  z-index: 6;
  position: absolute;
  top: calc(var(--toolbar-height) + var(--top-border));
  right: var(--parent-scollbar-width);
  border-top: var(--top-border) solid grey;
  box-shadow: -6px 0 15px var(--v-grey-lighten3);
  /* don't show the shadow over the toolbar */
  clip-path: inset(0 0 0 -20px);
}

.toolbar-icon {
  position: absolute;
  top: 0;
  right: 8px;
}

.loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-table {
  display: grid;
  grid-gap: 12px 16px;
  grid-template-columns: auto 1fr;
  font-size: 16px;
  align-items: center;
}

.info-table dt,
.info-table .l {
  grid-column-start: 1;
}

.info-table dd,
.info-table .r {
  grid-column-start: 2;
}

.info-table .full-width {
  grid-column: 1 / 3;
}

.field-name {
  font-size: 16px;
  display: flex;
  color: var(--v-grey-lighten2);
}

.field-name span:first-child {
  padding-left: 32px;
}

.add-reservation {
  padding-left: 40px;
}
</style>
